<template>
  <div class="pageContol listWrap templateList1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">模拟考成绩查询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="试卷名称" class="searchboxItem ci-full">
              <span class="itemLabel">试卷名称:</span>
              <el-input v-model="retrievalData.paperName" type="text" size="small" placeholder="请输入试卷名称" clearable />
            </div>
            <!-- <div title="练习时间" class="searchboxItem ci-full">
              <span class="itemLabel">练习时间:</span>
              <el-date-picker v-model="retrievalData.practiceTime" size="small" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" />
            </div> -->
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="试卷名称" align="left" show-overflow-tooltip prop="paperName" minWidth="200px" />
              <el-table-column label="模拟考试名称" align="left" show-overflow-tooltip prop="mockName" minWidth="200px" />
              <el-table-column label="试题数量" align="left" show-overflow-tooltip prop="questionNum" minWidth="200px" />
              <el-table-column label="试题总分" align="left" show-overflow-tooltip prop="score" minWidth="100" />
              <el-table-column label="练习人数" align="left" show-overflow-tooltip prop="personNum" minWidth="100" />
              <el-table-column label="操作人" align="left" show-overflow-tooltip prop="operator" minWidth="100" />
              <el-table-column label="操作时间" align="left" show-overflow-tooltip minWidth="200px">
                <template slot-scope="scope">
                  {{
                    scope.row.createTimeStr | momentDate
                  }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="120px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="goNameList(scope.row)">查看名单</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "inkQuestionBank_modelExamScoreQuery_modelExamScoreQueryList",
  components: {
    Empty,
    PageNum
  },
  data() {
    return {
      // 检索数据
      retrievalData: {
        paperName: "", // 试卷名称
        practiceTime:[], // 练习时间
      },
    };
  },
  mixins: [List],
  created() { },
  computed: {},
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.retrievalData.paperName) {
        params.paperName = this.retrievalData.paperName;
      }
      if (this.retrievalData.practiceTime) {
        params.createTimeStart = this.retrievalData.practiceTime[0];
        params.createTimeEnd = this.retrievalData.practiceTime[1];
      }
      this.doFetch({
        url: "/biz/exam/bank/mock/scorePagerList",
        params,
        pageNum
      }, true, 6);
    },
    // 查看名单
    goNameList(row) {
      this.$router.push({
        path: "/inkQuestionBank/modelExamScoreQuery/modelExamScoreQueryListInfo",
        query: {
          mockId: row.mockId,
          paperId: row.paperId
        }
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>

<style lang="less" scoped></style>